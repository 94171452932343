/* timeline smart render */

// scrollable should be = true
// horizontal and vertical scroll -> dhx_timeline_scrollable_data

export default function(scheduler){

function contains(section, eventId){
	for(let i = 0; i < section.length; i++){
		if(section[i].id == eventId){
			return true;
		}
	}
	return false;
}

scheduler._timeline_smart_render = {
	_prepared_events_cache: null,
	_rendered_events_cache: [],
	_rendered_header_cache: [],
	_rendered_labels_cache: [],
	_rows_to_delete: [],
	_rows_to_add: [],
	_cols_to_delete: [],
	_cols_to_add: [],

	getViewPort: function(scrollHelper, resizeHeight, scrollLeft, scrollTop){
		// top/left/height/width of viewport with scroll pos
		var scrollBlock = scheduler.$container.querySelector(".dhx_cal_data");
		var coords = scrollBlock.getBoundingClientRect();

		var scrollableContainer = scheduler.$container.querySelector(".dhx_timeline_scrollable_data");
		if(scrollableContainer && scrollLeft === undefined){
			scrollLeft = scrollHelper.getScrollValue(scrollableContainer);
		}
		if(scrollTop === undefined){
			if(scrollableContainer){
				scrollTop = scrollableContainer.scrollTop;
			}else{
				scrollTop = scrollBlock.scrollTop;
			}
		}

		var copy = {};
		for(var i in coords){
			copy[i] = coords[i];
		}

		copy.scrollLeft = scrollLeft || 0;
		copy.scrollTop = scrollTop || 0;
		if (resizeHeight)
			coords.height = resizeHeight;

		return copy;
	},
	isInXViewPort: function (item, viewPort) {
		var viewPortLeft =  viewPort.scrollLeft;
		var viewPortRight = viewPort.width + viewPort.scrollLeft;

		// return true/false for item in/not in viewport on X axis
		return (item.left < viewPortRight + 100 && item.right > viewPortLeft - 100); // +100 and -100 spreads viewport width
	},
	isInYViewPort: function (item, viewPort) {
		var viewPortTop =  viewPort.scrollTop;
		var viewPortBottom = viewPort.height + viewPort.scrollTop;

		// return true/false for item in/not in viewport on Y axis
		return (item.top < (viewPortBottom + 80) && item.bottom > viewPortTop - 80); // +30 and -30 spreads viewport height
	},

	getVisibleHeader: function(view, viewPort) {
		var curHeader = '';
		this._rendered_header_cache = [];

		for (var i in view._h_cols) {
			var col = view._h_cols[i];
			if (this.isInXViewPort({left: col.left, right: col.left + scheduler._cols[i]}, viewPort)) {
				var html = col.div.outerHTML;
				curHeader += html;

				this._rendered_header_cache.push(col.div.getAttribute("data-col-id"));
			}
		}

		return curHeader;
	},

	updateHeader: function(view, viewPort, parent) {
		this._cols_to_delete = [];
		this._cols_to_add = [];

		var headers = scheduler.$container.querySelectorAll(".dhx_cal_header > div");
		var cells = headers[headers.length - 1].querySelectorAll(".dhx_scale_bar");// take cells of bottom scale

		var visibleItems = [];
		for(var i = 0; i < cells.length; i++){
			visibleItems.push(cells[i].getAttribute("data-col-id"));
		}

		// find new elements
		var res = this.getVisibleHeader(view, viewPort);
		if (!res)
			return;

		var renderers = this._rendered_header_cache.slice();
		var itemsToDel = [];

		for (var i = 0, len = visibleItems.length; i < len; i++) {
			var pos = renderers.indexOf(visibleItems[i]);
			if ( pos > -1) {
				renderers.splice(pos, 1);
			} else {
				itemsToDel.push(visibleItems[i]);
			}
		}

		if (itemsToDel.length) {
			this._cols_to_delete = itemsToDel.slice();
			this._deleteHeaderCells(itemsToDel, view, parent);
		}
		if (renderers.length)  {
			this._cols_to_add = renderers.slice();
			this._addHeaderCells(renderers, view, parent);
		}
	},

	_deleteHeaderCells: function(items, view, parent) {
		for (var i = 0; i < items.length; i++) {
			var item = parent.querySelector('[data-col-id="'+items[i]+'"]');
			if(item){
				parent.removeChild(item);
			}
		}
	},

	_addHeaderCells: function(items, view, parent) {
		var html = '';
		for (var i = 0; i < items.length; i++) {
			html += view._h_cols[items[i]].div.outerHTML;
		}

		const buffer = document.createElement('template');
		buffer.innerHTML = html;
		parent.appendChild(buffer.content);

	},

	getVisibleLabels: function(view, viewPort) {
		if (!view._label_rows.length) return;

		var curLabelCol = '';

		this._rendered_labels_cache = [];
		for (var i = 0; i < view._label_rows.length; i++) {
			if (this.isInYViewPort({top: view._label_rows[i].top, bottom: view._label_rows[i].top + view._section_height[view.y_unit[i].key]}, viewPort)) {
				var html = view._label_rows[i].div;
				curLabelCol += html;

				this._rendered_labels_cache.push(i);
			}
		}

		return curLabelCol;
	},

	updateLabels: function(view, viewPort, parent) {
		this._rows_to_delete = [];
		this._rows_to_add = [];

		let visibleItems = [];
		const gridRows = scheduler.$container.querySelectorAll(".dhx_timeline_label_row");
		gridRows.forEach((row) => {
			visibleItems.push(Number(row.getAttribute("data-row-index")));
		});

		// is it realy no visible items? check it again
		if (!visibleItems.length) {
			this.getVisibleLabels(view, viewPort);
			visibleItems = this._rendered_labels_cache.slice();
		}

		// find new elements
		var res = this.getVisibleLabels(view, viewPort);
		if (!res)
			return;

		var renderers = this._rendered_labels_cache.slice();

		var itemsToDel = [];

		for (var i = 0, len = visibleItems.length; i < len; i++) {
			var pos = renderers.indexOf(visibleItems[i]);
			if ( pos > -1) {
				renderers.splice(pos, 1);
			} else {
				itemsToDel.push(visibleItems[i]);
			}
		}

		if (itemsToDel.length) {
			this._rows_to_delete = itemsToDel.slice();
			this._deleteLabelCells(itemsToDel, view, parent);
		}
		if (renderers.length) {
			this._rows_to_add = renderers.slice();
			this._addLabelCells(renderers, view, parent);
		}
	},

	_deleteLabelCells: function(items, view, parent) {
		for (var i = 0; i < items.length; i++) {
			var item = parent.querySelector('[data-row-index="'+items[i]+'"]');
			if(item){
				parent.removeChild(item);
			}
		}
	},

	_addLabelCells: function(items, view, parent) {
		var html = '';
		for (var i = 0; i < items.length; i++) {
			html += view._label_rows[items[i]].div;
		}
		const buffer = document.createElement('template');
		buffer.innerHTML = html;
		parent.appendChild(buffer.content);
	},

	clearPreparedEventsCache: function () {
		this.cachePreparedEvents(null);
	},
	cachePreparedEvents: function(events){
		this._prepared_events_cache = events;
		this._prepared_events_coordinate_cache = events;

	},

	getPreparedEvents: function(view){
		var evs;
		if(this._prepared_events_cache){
			evs = this._prepared_events_cache;

			if(scheduler.getState().drag_id){
				// events grouped by sections
				// the whole structure is not updated during drag and drop
				// smart render needs moved event to be under right section here
				// manually place event under right section
				const dragId = scheduler.getState().drag_id;
				let stop = false;
				

				let found = false;

				evs.forEach((section, index) => {
					if(stop){
						return;
					}
					const currentSectionId = view.y_unit[index];
					for(let eventIndex = 0; eventIndex < section.length; eventIndex++){
						const ev = section[eventIndex];

						if(ev.id == dragId){

							if(ev[view.y_property] !== currentSectionId){
								found = true;
								section.splice(eventIndex, 1);
								eventIndex--;

								const actualEventSectionIndex = view.order[ev[view.y_property]];
								if(evs[actualEventSectionIndex] != section && evs[actualEventSectionIndex] && !contains(evs[actualEventSectionIndex], ev.id)){
									evs[actualEventSectionIndex].push(ev);
								}
							}
						}

					}
				
					if(found){
						stop = true;
					}

				});
			}
		}else{
			evs = scheduler._prepare_timeline_events(view);
			evs.$coordinates = {};
			this.cachePreparedEvents(evs);




			//var x_start = scheduler._timeline_getX(evs[i][m], false, view);
			//		var x_end = scheduler._timeline_getX(evs[i][m], true, view);
		}
		return evs;
	},

	updateEvents: function(view, viewPort) {
		var eventsBySections = this.getPreparedEvents(view);

		var renderedEvents = this._rendered_events_cache.slice();
		this._rendered_events_cache = [];

		var grid = scheduler.$container.querySelector('.dhx_cal_data .dhx_timeline_data_col');
		if (!grid) return;

		const willAdd = [];
		for (var i = 0; i < this._rendered_labels_cache.length; i++) {
			var row = this._rendered_labels_cache[i];
			var eventsToAdd = [];

			const sectionId = view.y_unit[row].key;
			var visibleRowEvents = renderedEvents[row] ? renderedEvents[row].slice() : [];
			scheduler._timeline_calculate_event_positions.call(view, eventsBySections[row]);
			var renderers = scheduler._timeline_smart_render.getVisibleEventsForRow(view, viewPort, eventsBySections, row);

			for (var item = 0, lenRend = renderers.length; item < lenRend; item++) {
				var pos = visibleRowEvents.indexOf(String(renderers[item].id));
				if ( pos > -1) {

					if(scheduler.getState().drag_id == renderers[item].id){
						for(let r = 0; r < visibleRowEvents.length; r++){
							if(visibleRowEvents[r] == renderers[item].id){
								visibleRowEvents.splice(r, 1);
								r--;
							}
						}
					}else{
						visibleRowEvents.splice(pos, 1);
					}

				} else {
					eventsToAdd.push(renderers[item]);
				}
			}

			var line = view._divBySectionId[sectionId];// grid.querySelector('[data-section-index="'+ row +'"]');
			if(!line){
				continue;
			}
			if (visibleRowEvents.length) {
				this._deleteEvents(visibleRowEvents, view, line);
			}
			
			const addEntry = {
				DOMParent: line,
				buffer: document.createElement('template')
			};
			willAdd.push(addEntry);
			if (eventsToAdd.length) {
				this._addEvents(eventsToAdd, view, addEntry.buffer, row);
			}
		}

		willAdd.forEach(function(rowEvents){
			rowEvents.DOMParent.appendChild(rowEvents.buffer.content);
		});
		scheduler._populate_timeline_rendered(scheduler.$container);
		view._matrix = eventsBySections;
	},

	_deleteEvents: function(events, view, parent) {
		for (var i = 0; i < events.length; i++) {
			const selector = '['+scheduler.config.event_attribute+'="'+ events[i] +'"]';
			var event = parent.querySelector(selector);
			if (event) {
				if (!event.classList.contains('dhx_in_move')){
					event.remove();
				} else {
					const parts = parent.querySelectorAll(selector);

					for(let i = 0; i < parts.length;i++){
						if (!parts[i].classList.contains('dhx_in_move')){
							parts[i].remove();
						}
					}
				}
			}
		}
	},

	_addEvents: function(events, view, parent, i) {
		// calculate height of all events but will render below only events in viewport
		var events_html = scheduler._timeline_update_events_html.call(view, events);
		parent.innerHTML = events_html;
	},

	getVisibleEventsForRow: function(view, viewPort, evs, rowIndex) {
		// get events only for viewport
		var evsInViewport = [];
		if (view.render == "cell") {
			evsInViewport = evs;
		} else {
			var rowEvents = evs[rowIndex];
			if (rowEvents) {
				for (var m = 0, evLen = rowEvents.length; m < evLen; m++) {
					var event = rowEvents[m];
					var coordinateCacheKey = rowIndex + '_' + event.id;
					var xStart, xEnd;
					if (evs.$coordinates && evs.$coordinates[coordinateCacheKey]) {
						xStart = evs.$coordinates[coordinateCacheKey].xStart;
						xEnd = evs.$coordinates[coordinateCacheKey].xEnd;
					} else {
						xStart = scheduler._timeline_getX(event, false, view);
						xEnd = scheduler._timeline_getX(event, true, view);

						if (evs.$coordinates) {
							evs.$coordinates[coordinateCacheKey] = {
								xStart: xStart,
								xEnd: xEnd
							};
						}
					}

					if (scheduler._timeline_smart_render.isInXViewPort({left: xStart, right: xEnd}, viewPort)) {
						evsInViewport.push(event);

						// save to cache
						if (!this._rendered_events_cache[rowIndex])
							this._rendered_events_cache[rowIndex] = [];
						this._rendered_events_cache[rowIndex].push(String(event.id));
					}
				}
			}
		}

		return evsInViewport;
	},

	getVisibleRowCellsHTML: function(view, viewPort, stats, evs, i) {
		// full render for new row uses _rendered_header_cache
		// that contains currently visible cols
		var dataWrapper = '';
		var cellLeftPos;

		var visibleColumns = this._rendered_header_cache;

		for (var ind = 0; ind < visibleColumns.length; ind++) {
			var j = visibleColumns[ind];

			cellLeftPos = view._h_cols[j].left - view.dx;

			if (scheduler._ignores[j]){

				if (view.render == "cell"){
					dataWrapper += scheduler._timeline_get_html_for_cell_ignores(stats);
				}else{
					dataWrapper += scheduler._timeline_get_html_for_bar_ignores();
				}
			}else {
				if (view.render == "cell"){
					dataWrapper += scheduler._timeline_get_html_for_cell(j, i, view, evs[i][j], stats, cellLeftPos);
				}else{
					dataWrapper += scheduler._timeline_get_html_for_bar(j, i, view, evs[i], cellLeftPos);
				}
			}
		}

		return dataWrapper;
	},

	getVisibleTimelineRowsHTML: function(view, viewPort, evs, rowIndex) {
		var dataWrapper = '';
		var stats = scheduler._timeline_get_cur_row_stats(view, rowIndex);
		stats = scheduler._timeline_get_fit_events_stats(view, rowIndex, stats);

		var cachedRow = view._label_rows[rowIndex];

		var template = scheduler.templates[view.name + "_row_class"];
		var templateParams = { view: view, section: cachedRow.section, template: template};
		// check vertical direction
		if (view.render == "cell") {
			dataWrapper += scheduler._timeline_get_html_for_cell_data_row(rowIndex, stats, cachedRow.top, cachedRow.section.key, templateParams);
			dataWrapper += this.getVisibleRowCellsHTML(view, viewPort, stats, evs, rowIndex);
			dataWrapper += '</div>';
		} else {
			//section 2
			dataWrapper += scheduler._timeline_get_html_for_bar_matrix_line(rowIndex, stats, cachedRow.top, cachedRow.section.key, templateParams);

			// section 3
			dataWrapper += scheduler._timeline_get_html_for_bar_data_row(stats, templateParams);
			dataWrapper += this.getVisibleRowCellsHTML(view, viewPort, stats, evs, rowIndex);
			dataWrapper += "</div></div>";
		}

		return dataWrapper;
	},

	updateGridRows: function(view, viewPort) {
		if (this._rows_to_delete.length) {
			this._deleteGridRows(this._rows_to_delete, view);
		}
		if (this._rows_to_add.length) {
			this._addGridRows(this._rows_to_add, view, viewPort);
		}


	},

	_deleteGridRows: function(items, view) {
		var parent = scheduler.$container.querySelector('.dhx_cal_data .dhx_timeline_data_col');
		if (!parent) return;

		for (var i = 0; i < items.length; i++) {
			const sectionId = view.y_unit[items[i]] ? view.y_unit[items[i]].key : null;
			if(view._divBySectionId[sectionId]){
				view._divBySectionId[sectionId].remove();
				delete view._divBySectionId[sectionId];
			}
			//var item = parent.querySelector('[data-section-index="'+(items[i])+'"]');
			//parent.removeChild(item);
		}

		this._rows_to_delete = [];
	},

	_addGridRows: function(items, view, viewPort) {
		var parent = scheduler.$container.querySelector('.dhx_cal_data .dhx_timeline_data_col');
		if (!parent) return;

		var evs = this.getPreparedEvents(view);

		var html = '';
		for (var i = 0; i < items.length; i++) {
			html += this.getVisibleTimelineRowsHTML(view, viewPort, evs, items[i]);

		}
		const buffer = document.createElement('template');
		buffer.innerHTML = html;
		parent.appendChild(buffer.content);

		//view._divBySectionId[items[i]].remove();
		//delete view._divBySectionId[items[i]];
		var parent = scheduler.$container.querySelector('.dhx_cal_data .dhx_timeline_data_col');
		view._divBySectionId = {};
		for(let i = 0, len = parent.children.length; i < len; i++){
			var child = parent.children[i];
			if(child.hasAttribute("data-section-id")){
				view._divBySectionId[child.getAttribute("data-section-id")] = child;
			}
		}

		for (var i = 0; i < items.length; i++) {
			const sectionId = view.y_unit[items[i]] ? view.y_unit[items[i]].key : null;
			scheduler._timeline_finalize_section_add(view, view.y_unit[items[i]].key, view._divBySectionId[sectionId]);
		}
		if (scheduler._mark_now) {
			scheduler._mark_now();
		}
		this._rows_to_add = [];
	},

	updateGridCols: function(view, viewPort) {
		var visibleHeaderColumns = this._rendered_header_cache;

		var visibleHeaderColumnsHash = {};
		for(var i = 0; i < visibleHeaderColumns.length; i++){
			visibleHeaderColumnsHash[visibleHeaderColumns[i]] = true;
		}

		const allRows = scheduler.$container.querySelectorAll(".dhx_timeline_data_row");
		allRows.forEach((function(row){
			const allCells = row.querySelectorAll("[data-col-id]");

			const renderedCells = Array.prototype.reduce.call(allCells, function(hash, cell){
				hash[cell.dataset.colId] = cell;
				return hash;
			}, {});

			var shouldDelete = [],
			shouldRender = [];
			for(var i in renderedCells){
				if(!visibleHeaderColumnsHash[i]){
					shouldDelete.push(renderedCells[i]);
				}
			}
			for(var i in visibleHeaderColumnsHash){
				if(!renderedCells[i]){
					shouldRender.push(i);
				}
			}

			shouldDelete.forEach(function(cell){
				cell.remove();
			});
			if(shouldRender.length){
				this._addGridCols(row, shouldRender, view, viewPort);
			}
				
			
		}).bind(this));
	},

	_addGridCols: function(row, items, view, viewPort) {
		var grid = scheduler.$container.querySelector('.dhx_cal_data .dhx_timeline_data_col');
		if (!grid) return;

		var evs = this.getPreparedEvents(view);

		for (var r = 0; r < this._rendered_labels_cache.length; r++) {
			var i = this._rendered_labels_cache[r];
			var html = '';
			var stats = scheduler._timeline_get_cur_row_stats(view, i);
			stats = scheduler._timeline_get_fit_events_stats(view, i, stats);

			var parent = row;


			if (parent) {
				for (var j = 0; j < items.length; j++) {
					var item = parent.querySelector('[data-col-id="'+items[j]+'"]');

					if (!item) {
						var cell = this.getVisibleGridCell(view, viewPort, stats, evs, i, items[j]);
						if (cell)
							html += cell;
					}
				}

				const buffer = document.createElement('template');
				buffer.innerHTML = html;
				parent.appendChild(buffer.content);
			}
		}

		//this._cols_to_add = [];
	},

	getVisibleGridCell: function(view, viewPort, stats, evs, i, cellIndex) {
		if (!view._h_cols[cellIndex]) return;
		var dataWrapper = '';
		var cellLeftPos = view._h_cols[cellIndex].left-view.dx;

		if (view.render == "cell") {
			if (scheduler._ignores[cellIndex]){
				//dataWrapper += scheduler._timeline_get_html_for_cell_ignores(stats);
			}else {
				dataWrapper += scheduler._timeline_get_html_for_cell(cellIndex, i, view, evs[i][cellIndex], stats, cellLeftPos);
			}
		} else {
			if (scheduler._ignores[cellIndex]){
				//dataWrapper += scheduler._timeline_get_html_for_bar_ignores();
			}else {
				dataWrapper += scheduler._timeline_get_html_for_bar(cellIndex, i, view, evs[i], cellLeftPos);
			}
		}

		return dataWrapper;
	}
};

scheduler.attachEvent("onClearAll", function(){
	scheduler._timeline_smart_render._prepared_events_cache = null;
	scheduler._timeline_smart_render._rendered_events_cache = [];
});

/* timeline smart render end */

}