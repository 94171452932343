export default {
	date: {
		month_full: ["Ιανουάριος", "Φεβρουάριος", "Μάρτιος", "Απρίλιος", "Μάϊος", "Ιούνιος", "Ιούλιος", "Αύγουστος", "Σεπτέμβριος", "Οκτώβριος", "Νοέμβριος", "Δεκέμβριος"],
		month_short: ["ΙΑΝ", "ΦΕΒ", "ΜΑΡ", "ΑΠΡ", "ΜΑΙ", "ΙΟΥΝ", "ΙΟΥΛ", "ΑΥΓ", "ΣΕΠ", "ΟΚΤ", "ΝΟΕ", "ΔΕΚ"],
		day_full: ["Κυριακή", "Δευτέρα", "Τρίτη", "Τετάρτη", "Πέμπτη", "Παρασκευή", "Σάββατο"],
		day_short: ["ΚΥ", "ΔΕ", "ΤΡ", "ΤΕ", "ΠΕ", "ΠΑ", "ΣΑ"]
	},
	labels: {
		dhx_cal_today_button: "Σήμερα",
		day_tab: "Ημέρα",
		week_tab: "Εβδομάδα",
		month_tab: "Μήνας",
		new_event: "Νέο έργο",
		icon_save: "Αποθήκευση",
		icon_cancel: "Άκυρο",
		icon_details: "Λεπτομέρειες",
		icon_edit: "Επεξεργασία",
		icon_delete: "Διαγραφή",
		confirm_closing: "", //Your changes will be lost, are your sure ?
		confirm_deleting: "Το έργο θα διαγραφεί οριστικά. Θέλετε να συνεχίσετε;",
		section_description: "Περιγραφή",
		section_time: "Χρονική περίοδος",
		full_day: "Πλήρης Ημέρα",

		/*recurring events*/
		confirm_recurring: "Θέλετε να επεξεργασθείτε ολόκληρη την ομάδα των επαναλαμβανόμενων έργων;",
		section_recurring: "Επαναλαμβανόμενο έργο",
		button_recurring: "Ανενεργό",
		button_recurring_open: "Ενεργό",
		button_edit_series: "Επεξεργαστείτε τη σειρά",
		button_edit_occurrence: "Επεξεργασία ένα αντίγραφο",

		/*agenda view extension*/
		agenda_tab: "Ημερήσια Διάταξη",
		date: "Ημερομηνία",
		description: "Περιγραφή",

		/*year view extension*/
		year_tab: "Έτος",

		/*week agenda view extension*/
		week_agenda_tab: "Ημερήσια Διάταξη",

		/*grid view extension*/
		grid_tab: "Πλέγμα",

		/* touch tooltip*/
		drag_to_create:"Drag to create",
		drag_to_move:"Drag to move",

		/* dhtmlx message default buttons */
		message_ok:"OK",
		message_cancel:"Cancel",

		/* wai aria labels for non-text controls */
		next: "Next",
		prev: "Previous",
		year: "Year",
		month: "Month",
		day: "Day",
		hour:"Hour",
		minute: "Minute",

		/* recurring event components */
		repeat_radio_day: "Ημερησίως",
		repeat_radio_week: "Εβδομαδιαίως",
		repeat_radio_month: "Μηνιαίως",
		repeat_radio_year: "Ετησίως",
		repeat_radio_day_type: "Κάθε",
		repeat_text_day_count: "ημέρα",
		repeat_radio_day_type2: "Κάθε εργάσιμη",
		repeat_week: " Επανάληψη κάθε",
		repeat_text_week_count: "εβδομάδα τις επόμενες ημέρες:",
		repeat_radio_month_type: "Επανάληψη",
		repeat_radio_month_start: "Την",
		repeat_text_month_day: "ημέρα κάθε",
		repeat_text_month_count: "μήνα",
		repeat_text_month_count2_before: "κάθε",
		repeat_text_month_count2_after: "μήνα",
		repeat_year_label: "Την",
		select_year_day2: "του",
		repeat_text_year_day: "ημέρα",
		select_year_month: "μήνα",
		repeat_radio_end: "Χωρίς ημερομηνία λήξεως",
		repeat_text_occurences_count: "επαναλήψεις",
		repeat_radio_end3: "Λήγει την",
		repeat_radio_end2: "Μετά από",
		month_for_recurring: ["Ιανουάριος", "Φεβρουάριος", "Μάρτιος", "Απρίλιος", "Μάϊος", "Ιούνιος", "Ιούλιος", "Αύγουστος", "Σεπτέμβριος", "Οκτώβριος", "Νοέμβριος", "Δεκέμβριος"],
		day_for_recurring: ["Κυριακή", "Δευτέρα", "Τρίτη", "Τετάρτη", "Πέμπτη", "Παρασκευή", "Σάββατο"]
	}
};

